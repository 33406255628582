import {
  createSpace,
  createAxes,
  makeResponsive,
  placeTitle,
  placeImage,
  placeInput,
  placeSlider,
  hoverMe,
  placeRec,
  hiddenPt,
  fixedPt,
  clearInputFields,
  dragMe,
  placeArrow,
  placeGravity,
  placeText,
  placeMiddleText,
  placeLine,
//  placePoint,
  placeGlider,
  placeRuler,
  placeLeftText,
  placeCircle,
  placeAngle,
  drawPoint,
//  placeDash,
  placeLabel,
  placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
  placeArc,
  placeLogo,
  placeErase,
  placeShuffle,
  placeTest,
  placeSeg,
  setConfig,
  placeStat,
  //placeTri,
  shuffle,
  placeEraseLeft,
  placeTapeHori,
  placeTapeVert,
  placeSq,
  placeBoldText,
  placeRedo,
  placeUndo,
  setSize,
  labelIt,
  drawSegment,
  drawDash,
  place3Circles,
  place3BCircles,
  placeEggs
} from '../Utils.js';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
      ///////////////////////////////////GLOBAL SETTINGS//////////////////////
      	JXG.Options.board.minimizeReflow = 'none';
        JXG.Options.point.showInfoBox=false;
        JXG.Options.point.highlight=false;
        JXG.Options.image.highlight=false;
        JXG.Options.line.highlight=false;
        JXG.Options.text.highlight=false;
        JXG.Options.text.fixed=true;
        JXG.Options.polygon.highlight=false;
        JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  //////////////////////////////////LOCAL SETTINGS////////////////////////////
  var graph =createSpace(-4,12,-4,12);
  graph.suspendUpdate();
  graph.options.layer['image']=26;
  graph.options.layer['text']=2;
  graph.options.layer['html']=2;
  graph.options.layer['line']=6;
  graph.options.layer['point']=10;
  graph.options.layer['polygon']=4;
  graph.options.image.highlight=false;
//    graph.options.text.display='internal';
/*********************GRAPH DIMENSIONS*******************/
const boundingBox = graph.attr.boundingbox;
const positionX = (boundingBox[2]+boundingBox[0])/2;
const positionY = (boundingBox[1]+boundingBox[3])/2;
const height = (boundingBox[1]-boundingBox[3])/2;
    //Make the board responsive
    makeResponsive(graph);
    // Creat titleStyle
    placeLogo(graph);
///////////////////////////////
  var i = 0;
  var eggs = placeEggs(graph);
  eggs[0].setAttribute({visible:()=>i>=0});
////////////////
  eggs[1].setAttribute({visible:()=>i>=1});
////////////////
  eggs[2].setAttribute({visible:()=>i>=2});
////////////////////////////////////////////
    placeTitle(graph, 'Area of a Parallelogram', '(Showing that area of a parallelogram is bxh)');
////////////////////////////////////////////////////////////////////////////////
    var next = placeRedo(graph, 'right');
    hoverMe(graph, next, 'Forward', 0, -5);
    ////////////////////////////////////////////////////
    var prev = placeUndo(graph, 'left');
    hoverMe(graph, prev, 'Backward', 0, -5);
/////////////////////////////////////////////////////////////////////////////
    var Pt0 = drawPoint(graph, -2, 0);
    setSize(graph, Pt0, 0);
    //labelIt(graph, Pt0, 'A');
    var Pt01 = drawPoint(graph, ()=>Pt0.X()+2, 0);
    setSize(graph, Pt01, 0);
    var Pt02 = drawPoint(graph, ()=>Pt0.X()+2, 4);
    //labelIt(graph, Pt02, 'B');
    setSize(graph, Pt02,0);
    var Pt1 = drawPoint(graph, 0, 0);
    setSize(graph, Pt1, 0);
    var Pt2 = drawPoint(graph, 0, 4);
    setSize(graph, Pt2, 0);
    var Pt3 = drawPoint(graph, 5, 0);
    setSize(graph, Pt3, 0);
    //labelIt(graph, Pt3, 'C');
    var Pt4 = drawPoint(graph, 7, 4);
    setSize(graph, Pt4, 0);
    drawSegment(graph, Pt0, Pt01);
    var hide1 =drawSegment(graph, Pt0, Pt02);
    var hide3 =drawSegment(graph, Pt01, Pt02);
    drawSegment(graph, Pt1, Pt3);
    var hide2=drawSegment(graph, Pt3, Pt4);
    hide3.setAttribute({visible:()=>i>0});
    hide1.setAttribute({visible:()=>i<2});
    hide2.setAttribute({visible:()=>i<2});
    drawSegment(graph, Pt2, Pt4);
    var hide4=drawSegment(graph, Pt1, Pt2);
    hide4.setAttribute({visible:()=>i>0});
    //labelIt(graph, Pt4, 'D');
    var pol1 = graph.create('polygon', [Pt0, Pt01, Pt02], {visible: true, fixed:true,  vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'green', strokeWidth: 0}, fillColor:function(){if(i<2){return 'green'}else{return 'red'}}, fillOpacity:1});
    var pol2 = graph.create('polygon', [Pt1, Pt3, Pt4, Pt2], {fixed:true, visible: true, vertices:{visible:false, fixed:true}, borders:{visible:false, strokeColor: 'green', strokeWidth: 0}, fillColor:function(){if(i<2){return 'green'}else{return 'red'}}, fillOpacity:1});
    ////////////////////////////
    var lin1 =drawDash(graph, Pt01, Pt02);
    lin1.setAttribute({strokeColor:'black'});
    ////////////////////////////////
    var lin =drawDash(graph, Pt3, Pt4);
    lin.setAttribute({strokeColor:'black'});
    var t1 = drawPoint(graph, 7.5, 0);
    setSize(graph, t1, 0);
    var t2 = drawPoint(graph, 7.5, 4);
    setSize(graph, t2, 0);
    var t3 = drawPoint(graph, 0, 4.5);
    setSize(graph, t3, 0);
    var t4 = drawPoint(graph, 7, 4.5);
    setSize(graph, t4, 0);
    var horz =placeTapeVert(graph, t3, t4, ' ');
    var vert =placeTapeHori(graph, t1, t2, ' ');
    horz.setAttribute({label:{visible:false}});
    vert.setAttribute({label:{visible:false}});
    placeMiddleText(graph, positionX-0.5, 4.85, 'b');
    placeMiddleText(graph, 7.85, 2, 'h');
    /////////////////////
    next.on('down', function(){
      if(i==0){
        Pt0.moveTo([-3, 0], 100);
      }
      if(i==1){
        Pt0.moveTo([5, 0], 100);
      }
      if(i<2){
      i+=1;}
    });
    prev.on('down', function(){
      if(i==2){
        Pt0.moveTo([-3, 0], 100);
      }
      if(i==1){
        Pt0.moveTo([-2, 0], 100);
      }
      if(i>0){
      i-=1;
    }
    });

    //Pt1.moveTo([0, ()=>Pt1.Y()]);
    //placeLabel(graph, Pt1, 'A', -15, 15);

    var line1 = placeMiddleText(graph, positionX, 7.5, 'Area of Green Parallelogram');
    line1.setAttribute({color:'green'});
    var line2 = placeLeftText(graph, positionX, 6.5, '= Area of Red Rectangle = b x h');
    line2.setAttribute({visible:()=>i>1, color:'red'});
    //line3.setAttribute({visible:()=>i>1, color:'green'});

///////////////////////////////////////////////////////////////////////
    graph.unsuspendUpdate();
                      },
              }
export default Boxes;
